const ManagementSettings = () => {
  const handleManagerInvite = (event) => {
    console.log(event);
  };
  return (
    <div className='w-full h-full flex'>
      <div className='flex flex-col w-full p-10'>
        <div className='flex'>
          <h1 className='text-5xl font-bold'>Settings</h1>
        </div>
        <hr className='mb-5 mt-3 border-zinc-900' />
        <div className='w-full h-full flex flex-col gap-12'>
          <div className='p-8'>
            <div className='text-2xl font-semibold pl-3'>
              Invite New Manager
            </div>
            <hr className='mb-5 mt-3 border-zinc-900' />
            <div className='w-full'>
              <form onSubmit={handleManagerInvite}>
                <div className='grid grid-cols-3 justify-around gap-12 w-full'>
                  {/* Manager Name */}
                  <div>
                    <label
                      htmlFor='name'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      Manager Name *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                      />
                    </div>
                  </div>
                  {/* Manager Email */}
                  <div>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      Manager Email *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                      />
                    </div>
                  </div>
                  <button
                    className='bg-red-600 rounded-full px-10 shadow-md hover:brightness-75 transition-all text-white font-semibold'
                    type='submit'
                  >
                    Invite
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementSettings;
