import {
  ChatBubbleLeftEllipsisIcon,
  CheckBadgeIcon,
  DocumentCheckIcon,
  GlobeAmericasIcon,
  PencilIcon,
  CheckCircleIcon,
  BriefcaseIcon,
  DocumentMagnifyingGlassIcon,
  XMarkIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import VisaCell from "./visa-cell";
import {
  createApplicant,
  fetchApplicantById,
} from "../../applications/applicationService";

const stepNames = [
  "Initial Application",
  "Pre-qualification",
  "Language Exams",
  "CGFNS",
  "NCLEX",
  "Visa Credentials",
  "Job Search",
  "Work Visa Application",
  "Complete",
];

const ApplicationModal = () => {
  const [isRejectionModal, setRejectionModal] = useState(false);

  let row = null;

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const applicant = await fetchApplicantById(id);
      row = applicant;
    };

    fetchData();
  }, []);

  const toggleRejectionModal = () => {
    setRejectionModal(!isRejectionModal);
  };

  const handleRejection = () => {
    //REJECT
    setRejectionModal(!isRejectionModal);
  };

  const handleAcceptance = () => {
    //ACCEPT
  };

  if (row == null) {
    row = createApplicant({});
  }

  const documentStepName = [
    "Misc 1",
    "Misc 2",
    "Language",
    "CGFNS",
    "NCLEX",
    "Visa Cred",
    "Job Search",
    "Work Visa",
    "Completion",
  ];

  return (
    <motion.div
      className={`w-full h-full`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='h-full w-full bg-white rounded-2xl shadow-lg p-10 overflow-y-auto overflow-x-hidden select-text bg-zinc-50'>
        <div className='flex justify-between mb-10'>
          <div className='text-4xl font-bold'>
            Application{" "}
            <span className='font-sans font-medium ml-2'>{row.id}</span>
          </div>
          <div className='h-full rounded-full p-3 hover:bg-zinc-400 hover:text-white cursor-pointer'>
            <Link to='/management/applications/all'>
              <XMarkIcon width={20} />
            </Link>
          </div>
        </div>

        {/* Progress */}
        <div className='my-5'>
          <h5 className='text-3xl font-bold'>Progress</h5>
          <hr className='my-5 border-zinc-900' />

          <div className='flex items-start max-w-screen-lg mx-auto mt-7'>
            <div className='w-full'>
              <div className='flex items-center w-full'>
                <div
                  className={`${row.status - 1 > 1 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 1 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <DocumentCheckIcon width={22} />
                  </span>
                </div>
                <div
                  className={`${row.status - 1 > 1 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
                ></div>
              </div>
              <div className='mt-2 mr-4'>
                <h6 className='text-base font-bold text-zinc-900'>Pre-Qual</h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 > 1 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex items-center w-full'>
                <div
                  className={`${row.status - 1 > 2 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 2 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <ChatBubbleLeftEllipsisIcon width={22} />
                  </span>
                </div>
                <div
                  className={`${row.status - 1 > 2 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
                ></div>
              </div>
              <div className='mt-2 mr-4'>
                <h6 className='text-base font-bold text-zinc-900'>Language</h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 > 2 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex items-center w-full'>
                <div
                  className={`${row.status - 1 > 3 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 3 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <CheckBadgeIcon width={22} />
                  </span>
                </div>
                <div
                  className={`${row.status - 1 > 3 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
                ></div>
              </div>
              <div className='mt-2 mr-4'>
                <h6 className='text-base font-bold text-zinc-900'>CGFNS</h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 > 3 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex items-center w-full'>
                <div
                  className={`${row.status - 1 > 4 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 4 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <PencilIcon width={22} />
                  </span>
                </div>
                <div
                  className={`${row.status - 1 > 4 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
                ></div>
              </div>
              <div className='mt-2 mr-4'>
                <h6 className='text-base font-bold text-zinc-900'>NCLEX</h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 > 4 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex items-center w-full'>
                <div
                  className={`${row.status - 1 > 5 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 5 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <GlobeAmericasIcon width={22} />
                  </span>
                </div>
                <div
                  className={`${row.status - 1 > 5 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
                ></div>
              </div>
              <div className='mt-2 mr-4'>
                <h6 className='text-base font-bold text-zinc-900'>Visa Cred</h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 > 5 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex items-center w-full'>
                <div
                  className={`${row.status - 1 > 6 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 6 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <DocumentMagnifyingGlassIcon width={22} />
                  </span>
                </div>
                <div
                  className={`${row.status - 1 > 6 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
                ></div>
              </div>
              <div className='mt-2 mr-4'>
                <h6 className='text-base font-bold text-zinc-900'>
                  Job Search
                </h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 > 6 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex items-center w-full'>
                <div
                  className={`${row.status - 1 > 7 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 7 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <BriefcaseIcon width={22} />
                  </span>
                </div>
                <div
                  className={`${row.status - 1 > 7 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
                ></div>
              </div>
              <div className='mt-2 mr-4'>
                <h6 className='text-base font-bold text-zinc-900'>Work Visa</h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 > 7 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
            <div>
              <div className='flex items-center'>
                <div
                  className={`${row.status - 1 == 8 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${row.status - 1 == 8 ? "ring-2 ring-red-600" : ""}`}
                >
                  <span className='text-base text-white font-bold'>
                    <CheckCircleIcon width={22} />
                  </span>
                </div>
              </div>
              <div className='mt-2'>
                <h6 className='text-base font-bold text-zinc-900'>
                  Completion
                </h6>
                <p className='text-xs text-gray-500'>
                  {row.status - 1 == 8 ? "Completed" : "Pending"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Current Step */}
        <div className='my-3'>
          <div className='my-5 w-full flex justify-center'>
            <div className='w-10/12 border border-2 border-zinc-900 bg-white rounded-lg col-span-4 md:col-span-2 lg:col-span-1'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                Current Step
              </h1>
              <div className='flex justify-center w-full'>
                <div className='flex flex-col items-center p-5 gap-4 w-full'>
                  <div className='text-lg font-bold'>
                    Current Step Name:
                    <span className='ml-2 font-medium'>
                      {stepNames[row.status - 1]}
                    </span>
                  </div>
                  <div className='text-lg font-bold flex flex-row items-center'>
                    Applicant Step Submission:
                    <div className='ml-2 font-medium'>
                      {row.status > 1 ? (
                        <div className='flex flex-row items-center gap-1 text-md hover:opacity-50 hover:cursor-pointer'>
                          Open <DocumentArrowDownIcon width={22} />
                        </div>
                      ) : (
                        "User has not submitted any verification yet"
                      )}
                    </div>
                  </div>
                  <div className='flex w-full justify-center'>
                    <div className='w-1/2 flex justify-around mt-3 gap-6'>
                      <button
                        className='select-none rounded-full p-3 px-32 bg-green-600 shadow-md border-green-500 text-sm font-bold text-white hover:bg-white hover:border-2 hover:text-green-500 hover:px-[126px] hover:py-[10px]'
                        onClick={() => handleAcceptance()}
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => toggleRejectionModal()}
                        className='select-none rounded-full p-3 px-32 bg-red-600 shadow-md border-red-500 text-sm font-bold text-white hover:bg-white hover:border-2 hover:text-red-500 hover:px-[126px] hover:py-[10px]'
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                  {isRejectionModal ? (
                    <div
                      className={`fixed z-40 top-0 h-full w-full bg-[rgba(0,0,0,0.4)] left-0 p-16`}
                    >
                      <div className='w-full h-full flex flex-col justify-center items-center p-8'>
                        <div className='w-full lg:w-2/3  p-5 px-10 bg-white rounded-lg shadow-lg'>
                          <div className='flex flex-row justify-end'>
                            <div
                              className='rounded-full p-2 hover:bg-zinc-400 hover:text-white cursor-pointer'
                              onClick={() => toggleRejectionModal()}
                            >
                              <XMarkIcon width={20} />
                            </div>
                          </div>
                          <h1 className='font-bold text-xl'>
                            Applicant Step Rejection Form
                          </h1>
                          <hr className='border-zinc-900 mt-2' />

                          <form method='POST' onSubmit=''>
                            <div className='w-full flex flex-col p-2 my-3'>
                              <label className='pl-3 font-semibold mb-1'>
                                Submission Rejection Reason:
                              </label>
                              <textarea
                                className='border border-zinc-900 rounded-lg shadow-md resize-none min-h-24 p-3'
                                placeholder='Our reason for rejecting your submission at this time was due to...'
                              />
                              <div className='w-full flex justify-around mt-6'>
                                <button
                                  onClick={() => toggleRejectionModal()}
                                  className='select-none rounded-full p-3 bg-zinc-600 shadow-md border-zinc-500 text-sm font-bold text-white hover:bg-white hover:border-2 hover:text-zinc-900 hover:p-[10px]'
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => handleRejection()}
                                  className='select-none rounded-full p-3 bg-red-600 shadow-md border-red-500 text-sm font-bold text-white hover:bg-white hover:border-2 hover:text-red-500 hover:p-[10px]'
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Application Info */}
          <h5 className='text-3xl font-bold'>Application Info</h5>
          <hr className='border-zinc-900 my-5' />
          <div className='grid grid-cols-4 gap-4 break-words'>
            {/* General Info */}
            <div className='border border-2 border-zinc-900 rounded-lg col-span-4 md:col-span-2 lg:col-span-1 bg-white'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                General Information
              </h1>
              <div className='flex flex-col p-5 gap-4'>
                <div className='text-lg font-bold'>
                  ID:
                  <span className='ml-2 font-medium'>{row.id}</span>
                </div>
                <div className='text-lg font-bold'>
                  Name:<span className='ml-2 font-medium'>{row.name}</span>
                </div>
                <div className='text-lg font-bold'>
                  Country:
                  <span className='ml-2 font-medium'>{row.country}</span>
                </div>
                <div className='text-lg font-bold'>
                  Nationality:
                  <span className='ml-2 font-medium'>{row.nationality}</span>
                </div>
                <div className='text-lg font-bold'>
                  DOB:
                  <span className='ml-2 font-medium'>{row.date_of_birth}</span>
                </div>
              </div>
            </div>

            {/* Contact Information */}
            <div className='border border-2 border-zinc-900 bg-white rounded-lg col-span-4 md:col-span-2 lg:col-span-1'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                Contact Information
              </h1>
              <div className='flex flex-col p-5 gap-4'>
                <div className='text-lg font-bold'>
                  Email:
                  <span className='ml-2 font-medium'>{row.email}</span>
                </div>
                <div className='text-lg font-bold'>
                  Phone Number:
                  <span className='ml-2 font-medium'>{row.phone_number}</span>
                </div>
                <div className='text-lg font-bold'>
                  Recruiter:
                  <span className='ml-2 font-medium'>{row.recruiter}</span>
                </div>
              </div>
            </div>

            {/* Work Information */}
            <div className='border border-2 bg-white border-zinc-900 rounded-lg col-span-4 md:col-span-2 lg:col-span-1'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                Work
              </h1>
              <div className='flex flex-col p-5 gap-4'>
                <div className='text-lg font-bold'>
                  Profession:
                  <span className='ml-2 font-medium'>{row.position_id}</span>
                </div>
                <div className='text-lg font-bold'>
                  Date License Issued:
                  <span className='ml-2 font-medium'>
                    {row.license_date_of_issuance}
                  </span>
                </div>
                <div className='text-lg font-bold'>
                  Date License Expires:
                  <span className='ml-2 font-medium'>
                    {row.license_date_of_expiry}
                  </span>
                </div>
                <div className='text-lg font-bold'>
                  License Details:
                  <span className='ml-2 font-medium'>
                    {row.license_details}
                  </span>
                </div>
                <div className='text-lg font-bold'>
                  YOE:
                  <span className='ml-2 font-medium'>{row.total_yoe}</span>
                </div>
                <div className='text-lg font-bold'>
                  Specialty:
                  <span className='ml-2 font-medium'>{row.specialty}</span>
                </div>
                <div className='text-lg font-bold'>
                  Notice Period:
                  <span className='ml-2 font-medium'>
                    {row.notice_period} {row.notice_period ? "days" : ""}
                  </span>
                </div>
              </div>
            </div>

            {/* Education Information */}
            <div className='border border-2 bg-white border-zinc-900 rounded-lg col-span-4 md:col-span-2 lg:col-span-1'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                Education
              </h1>
              <div className='flex flex-col p-5 gap-4'>
                <div className='text-lg font-bold'>
                  College:
                  <span className='ml-2 font-medium'>{row.school}</span>
                </div>
                <div className='text-lg font-bold'>
                  Degree:
                  <span className='ml-2 font-medium'>
                    {row.highest_educational_certificate}
                  </span>
                </div>
                <div className='text-lg font-bold'>
                  Major:
                  <span className='ml-2 font-medium'>{row.major}</span>
                </div>
                <div className='text-lg font-bold'>
                  Country Issued From:
                  <span className='ml-2 font-medium'>
                    {row.country_issuance}
                  </span>
                </div>
                <div className='text-lg font-bold'>
                  Four Year School?:
                  <span className='ml-2 font-medium'>
                    {row.four_year ? (
                      <span className='bg-green-500 shadow-md p-2 rounded-full text-white font-bold text-sm'>
                        True
                      </span>
                    ) : (
                      <span className='bg-red-500 shadow-md p-2 rounded-full text-white font-bold text-sm'>
                        False
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>

            {/* Language Information */}
            <div className='border border-2 bg-white border-zinc-900 rounded-lg col-span-4 md:col-span-2 lg:col-span-1'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                Languages
              </h1>
              {row.languages.length == 0 || row.languages == null ? (
                <div className='w-full h-full flex justify-center items-center'>
                  <div className='text-center text-2xl font-light text-gray-500'>
                    No Languages to Display
                  </div>
                </div>
              ) : (
                <div className='flex flex-col p-5 gap-4'>
                  {row.languages.map((language) => {
                    return (
                      <LanguageListItem key={language.id} language={language} />
                    );
                  })}
                </div>
              )}
            </div>

            {/* Immigration Information */}
            <div className='border border-2 bg-white border-zinc-900 rounded-lg col-span-4 md:col-span-2 lg:col-span-1'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                Immigration
              </h1>
              <div className='flex flex-col p-5 gap-4'>
                <div className='text-lg font-bold'>
                  Immigration Status:
                  <span className='ml-2 font-medium'>
                    {row.immigration_status}
                  </span>
                </div>
                <div className='text-lg font-bold flex flex-row'>
                  Visa:
                  <div className='ml-2 font-medium'>
                    {row.visa_status !== "" ? (
                      <VisaCell info={row.visa_status} />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
                <div className='text-lg font-bold'>
                  Visa Details:
                  <span className='ml-2 font-medium'>
                    {row.visa_details !== "" ? row.visa_details : "N/A"}
                  </span>
                </div>
                <div className='text-lg font-bold'>
                  Marital Status:
                  <span className='ml-2 font-medium'>{row.marital_status}</span>
                </div>
                <div className='text-lg font-bold'>
                  Number of Children:
                  <span className='ml-2 font-medium'>
                    {row.number_children}
                  </span>
                </div>
                <div className='text-lg font-bold'>
                  Country Drivers License Issued From:
                  <span className='ml-2 font-medium'>{row.dl_country}</span>
                </div>
                <div className='text-lg font-bold'>
                  Work Permit:
                  <span className='ml-2 font-medium'>
                    {row.work_permit ? (
                      <span className='bg-green-500 shadow-md p-2 rounded-full text-white font-bold text-sm'>
                        True
                      </span>
                    ) : (
                      <span className='bg-red-500 shadow-md p-2 rounded-full text-white font-bold text-sm'>
                        False
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>

            {/* Notes */}
            <div className='border border-2 bg-white border-zinc-900 rounded-lg col-span-4 lg:col-span-2'>
              <h1 className='text-lg font-semibold bg-zinc-900 text-white pl-5 py-1'>
                Notes and Timestamps
              </h1>
              <div className='flex flex-col p-5 gap-4'>
                <div className='text-lg font-bold'>
                  Notes:
                  <span className='ml-2 font-medium'>{row.notes}</span>
                </div>
                <div className='text-lg font-bold'>
                  Last Updated:
                  <span className='ml-2 font-medium'>{row.updated_at}</span>
                </div>
                <div className='text-lg font-bold'>
                  Application Created:
                  <span className='ml-2 font-medium'>{row.created_at}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Documents */}
          <h5 className='text-3xl font-bold mt-6'>Documents</h5>
          <hr className='border-zinc-900 my-5' />

          {row.documents.length > 0 ? (
            <div className='grid grid-cols-4 gap-4'>
              {row.documents.map((documentObj) => {
                return (
                  <a
                    key={documentObj.id}
                    href={documentObj.file_url}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='flex flex-col justify-center items-center border border-zinc-900 bg-white p-2 py-8 shadow-lg rounded-xl hover:cursor-pointer hover:bg-neutral-200 transition-all'>
                      <div>
                        <DocumentArrowDownIcon width={22} />
                      </div>
                      <div className='mt-2 font-semibold'>
                        {documentStepName[documentObj.applicant_step]}
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          ) : (
            <div className='w-full h-full p-10'>
              <h1 className='font-light text-gray-500 text-3xl text-center'>
                No Documents to Display
              </h1>
            </div>
          )}

          {/* Documents
           */}
        </div>
      </div>
    </motion.div>
  );
};

export default ApplicationModal;

export const LanguageListItem = ({ language }) => {
  return (
    <div className=''>
      <ul>
        <div className='font-bold'>{language.language}</div>

        <li className='pl-2 flex flex-row gap-1 items-center'>
          Reading:{" "}
          {Array.apply(null, Array(language.read))
            .map(function (x, i) {
              return i;
            })
            .map((val) => {
              return (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-5 h-5 fill-yellow-500'
                  key={val}
                >
                  <path
                    fillRule='evenodd'
                    d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z'
                    clipRule='evenodd'
                  />
                </svg>
              );
            })}
          {Array.apply(null, Array(4 - language.read))
            .map(function (x, i) {
              return i;
            })
            .map((val) => {
              return (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-5 h-5'
                  key={val}
                >
                  <path
                    fillRule='evenodd'
                    d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z'
                    clipRule='evenodd'
                  />
                </svg>
              );
            })}
        </li>
        <li className='pl-2 flex flex-row gap-1 items-center'>
          Speaking:{" "}
          {Array.apply(null, Array(language.speak))
            .map(function (x, i) {
              return i;
            })
            .map((val) => {
              return (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-5 h-5 fill-yellow-500'
                  key={val}
                >
                  <path
                    fillRule='evenodd'
                    d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z'
                    clipRule='evenodd'
                  />
                </svg>
              );
            })}
          {Array.apply(null, Array(4 - language.speak))
            .map(function (x, i) {
              return i;
            })
            .map((val) => {
              return (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-5 h-5'
                  key={val}
                >
                  <path
                    fillRule='evenodd'
                    d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z'
                    clipRule='evenodd'
                  />
                </svg>
              );
            })}
        </li>
        <li className='pl-2 flex flex-row gap-1 items-center'>
          Listening:{" "}
          {Array.apply(null, Array(language.listen))
            .map(function (x, i) {
              return i;
            })
            .map((val) => {
              return (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-5 h-5 fill-yellow-500'
                  key={val}
                >
                  <path
                    fillRule='evenodd'
                    d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z'
                    clipRule='evenodd'
                  />
                </svg>
              );
            })}
          {Array.apply(null, Array(4 - language.listen))
            .map(function (x, i) {
              return i;
            })
            .map((val) => {
              return (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-5 h-5'
                  key={val}
                >
                  <path
                    fillRule='evenodd'
                    d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z'
                    clipRule='evenodd'
                  />
                </svg>
              );
            })}
        </li>
      </ul>
      <div className='font-semibold mt-3 text-lg'>
        TOEFL Score: <span className='font-medium'>{language.toefl}</span>
      </div>
    </div>
  );
};
