import {
  Cog6ToothIcon,
  Squares2X2Icon,
  TableCellsIcon,
  DocumentCheckIcon,
  ArchiveBoxXMarkIcon,
  PlayIcon,
  ClockIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import { useOutlet } from "react-router-dom";
import { motion } from "framer-motion";


import SideBar, {
  NestedSidebarListItem,
  SidebarItem,
} from "../components/navigation/side-bar";

import {
  BarChart,
  Bar,
  Pie,
  PieChart,
  Cell,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from "recharts";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

let currentMonth = new Date().getMonth();
const last12Months = months
  .slice(currentMonth - 12)
  .concat(months.slice(0, currentMonth));

const barData = [
  { inbound: 2, completed: 6 },
  { inbound: 15, completed: 5 },
  { inbound: 19, completed: 1 },
  { inbound: 8, completed: 16 },
  { inbound: 15, completed: 6 },
  { inbound: 11, completed: 4 },
  { inbound: 2, completed: 6 },
  { inbound: 15, completed: 5 },
  { inbound: 19, completed: 1 },
  { inbound: 8, completed: 16 },
  { inbound: 15, completed: 6 },
  { inbound: 11, completed: 4 },
];

const scatterData = [
  { currentStep: 1, timeOnPlatform: 200, id: 2 },
  { currentStep: 3, timeOnPlatform: 100, id: 3 },
  { currentStep: 5, timeOnPlatform: 300, id: 6 },
  { currentStep: 5, timeOnPlatform: 250, id: 5 },
  { currentStep: 8, timeOnPlatform: 400, id: 1 },
  { currentStep: 7, timeOnPlatform: 280, id: 4 },
];

const statusData = [
  { name: "Applied", value: 400 },
  { name: "Pre-Qualified", value: 300 },
  { name: "Language", value: 300 },
  { name: "CGFNS", value: 200 },
  { name: "NCLEX", value: 278 },
  { name: "Visa Cred", value: 189 },
  { name: "Job Search", value: 189 },
  { name: "Work Visa", value: 189 },
  { name: "Completion", value: 189 },
  { name: "Stagnant", value: 189 },
];

const influxChartData = last12Months.map((month, i) => {
  return {
    name: month,
    inbound: barData[i].inbound,
    completed: barData[i].completed,
  };
});

const renderLineChart = (
  <ResponsiveContainer width='100%' height='100%'>
    <BarChart
      width={500}
      height={300}
      data={influxChartData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='name' />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='inbound' stackId='a' fill='rgb(8 145 178)' />
      <Bar dataKey='completed' stackId='a' fill='rgb(248 69 69)' />
    </BarChart>
  </ResponsiveContainer>
);

const renderCustomAxisTick = ({ x, y, payload }) => {
  let stepName = "";

  switch (payload.value) {
    case 1:
      stepName = "Applied";
      break;
    case 2:
      stepName = "PreQual";
      break;
    case 3:
      stepName = "Language";
      break;
    case 4:
      stepName = "CGFNS";
      break;
    case 5:
      stepName = "NCLEX";
      break;
    case 6:
      stepName = "Visa Cred";
      break;
    case 7:
      stepName = "Job Search";
      break;
    case 8:
      stepName = "Work Visa";
      break;
    case 9:
      stepName = "Completion";
      break;
  }

  return (
    <g transform={`translate(${x + 27},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='end' fill='#666'>
        {stepName}
      </text>
    </g>
  );
};

const renderScatterChart = (
  <ResponsiveContainer width='100%' height='100%'>
    <ScatterChart
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid />
      <XAxis
        type='number'
        dataKey='currentStep'
        name='currentStep'
        tick={renderCustomAxisTick}
      />
      <YAxis
        type='number'
        dataKey='timeOnPlatform'
        name='timeOnPlatform'
        unit=' days'
      />
      <ZAxis dataKey='id' type='string' name='ID' />
      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
      <Scatter
        name='FAS Scatter Graph'
        data={scatterData}
        fill='rgb(248 69 69)'
      />
    </ScatterChart>
  </ResponsiveContainer>
);

const COLORS = [
  "rgb(113 113 122)",
  "rgb(239 68 68)",
  "rgb(249 115 22)",
  "rgb(234 179 8)",
  "rgb(132 204 22)",
  "rgb(34 197 94)",
  "rgb(20 184 166)",
  "rgb(59 130 246)",
  "rgb(99 102 241)",
  "rgb(139 92 246)",
];

const renderPieChart = (
  <ResponsiveContainer width='100%' height='100%'>
    <PieChart>
      <Pie
        data={statusData}
        cx='50%'
        cy='50%'
        labelLine={false}
        label
        outerRadius={140}
        fill='#8884d8'
        dataKey='value'
      >
        {statusData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip contentStyle={{ borderRadius: "0.75rem" }} />
    </PieChart>
  </ResponsiveContainer>
);

const ManagementDashboard = () => {
  const outlet = useOutlet();

  return (
    <div className='h-full min-w-[600px] select-none'>
      <div className='flex h-full'>
        <SideBar>
          <SidebarItem
            icon={<Squares2X2Icon width={20} />}
            text='Dashboard'
            href='/management'
          />
          <hr className='my-3' />
          <NestedSidebarListItem
            headChild={{ icon: <InboxIcon width={20} />, text: "Applications" }}
          >
            <SidebarItem
              icon={<TableCellsIcon width={20} />}
              text='All Applications'
              href='/management/applications/all'
            />
            <SidebarItem
              icon={<ArchiveBoxXMarkIcon width={20} />}
              text='Unqualified'
              href='/management/applications/unqualified'
            />
            <SidebarItem
              icon={<PlayIcon width={20} />}
              text='In Progress'
              href='/management/applications/inprogress'
            />
            <SidebarItem
              icon={<ClockIcon width={20} />}
              text='Waiting For Review'
              href='/management/applications/waitingforreview'
            />
            <SidebarItem
              icon={<DocumentCheckIcon width={20} />}
              text='Completed'
              href='/management/applications/completed'
            />
          </NestedSidebarListItem>
          <hr className='my-3' />
          <SidebarItem
            icon={<Cog6ToothIcon width={20} />}
            text='Settings'
            href='/management/settings'
          />
        </SideBar>
        {outlet || (
          <motion.div
            className='w-full h-full bg-stone-200 p-8 overflow-y-auto'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <span className='p-5 text-4xl font-bold'>Dashboard</span>
            <div className='grid grid-cols-4 gap-8 p-2 mt-5'>
              <div className='flex flex-col justify-center items-center col-span-4 lg:col-span-2 2xl:col-span-1 p-6 shadow-lg rounded-2xl bg-white h-96 w-full'>
                <span className='text-lg font-bold'>
                  Inbound and Outbound Applications
                </span>
                {renderLineChart}
              </div>
              <div className='flex flex-col justify-center items-center col-span-4 lg:col-span-2 2xl:col-span-1 p-5 shadow-lg rounded-2xl bg-white h-96 w-full'>
                <span className='text-lg font-bold'>
                  Time on Platform Vs Current Step
                </span>
                {renderScatterChart}
              </div>
              <div className='flex flex-col justify-center items-center col-span-4 p-5 shadow-lg rounded-2xl bg-white h-[460px] pt-8 w-full'>
                <span className='text-lg font-bold'>
                  Applications by Current Step
                </span>
                {renderPieChart}
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ManagementDashboard;
