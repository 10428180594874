import logo from "../../images/logo-no-bird.png";
import { Link } from "react-router-dom";

// const footerItems = [
//     { name: 'Home', href: '/'},
// ];

const Footer = () => {
  return (
    <div className='flex justify-end w-full bg-white z-30 overflow-hidden'>
      <footer className='w-full text-gray-700 bg-gray-200 body-font'>
        <div className='flex flex-row flex-wrap px-5 py-16 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap'>
          <div className='flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left mb-10'>
            <a className='flex items-center justify-center font-medium text-gray-900 title-font md:justify-start'>
              <img
                className='h-8 w-auto hover:brightness-110 transition-all'
                src={logo}
                alt='Fly Away Students Logo'
                draggable='false'
              />
            </a>
            <p className='mt-2 text-sm text-gray-500'>
              Empowering Medical Workers, Enriching Healthcare
            </p>
            <div className='mt-4'>
              <span className='inline-flex justify-center mt-2 sm:ml-auto sm:mt-0 sm:justify-start'>
                <a
                  className='text-gray-500 cursor-pointer hover:text-gray-700'
                  href='https://www.facebook.com/flyawaystudents/'
                >
                  <svg
                    fill='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    className='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                    <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                  </svg>
                </a>

                <a
                  className='ml-3 text-gray-500 cursor-pointer hover:text-gray-700'
                  href='https://www.linkedin.com/company/flyawaystudents/about/'
                >
                  <svg
                    fill='currentColor'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='0'
                    className='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                    <path
                      stroke='none'
                      d='M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z'
                    ></path>
                    <circle cx='4' cy='4' r='2' stroke='none'></circle>
                  </svg>
                </a>
              </span>
            </div>
          </div>
          <div className='grid grid-cols-2 md:grid-cols-4 flex-grow -mb-10 text-center md:pl-20 md:mt-0 md:text-left'>
            <div className='w-full px-4 lg:w-1/4 md:w-1/2'>
              <h2 className='mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font'>
                Resources
              </h2>
              <nav className='mb-10 list-none'>
                <li className='mt-3'>
                  <a className='text-gray-500 cursor-pointer hover:text-gray-900'>
                    How It Works
                  </a>
                </li>
                <li className='mt-3'>
                  <a className='text-gray-500 cursor-pointer hover:text-gray-900'>
                    FAQ
                  </a>
                </li>
                <li className='mt-3'>
                  <a className='text-gray-500 cursor-pointer hover:text-gray-900'>
                    About Us
                  </a>
                </li>
              </nav>
            </div>
            <div className='w-full px-4 lg:w-1/4 md:w-1/2'>
              <h2 className='mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font'>
                Support
              </h2>
              <nav className='mb-10 list-none'>
                <li className='mt-3'>
                  <Link
                    to='/contact#usbranch'
                    className='text-gray-500 cursor-pointer hover:text-gray-900'
                  >
                    Contact Support
                  </Link>
                </li>
                <li className='mt-3'>
                  <a className='text-gray-500 cursor-pointer hover:text-gray-900'>
                    Help Resources
                  </a>
                </li>
              </nav>
            </div>
            <div className='w-full px-4 lg:w-1/4 md:w-1/2'>
              <h2 className='mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font'>
                Platform
              </h2>
              <nav className='mb-10 list-none'>
                <li className='mt-3'>
                  <a className='text-gray-500 cursor-pointer hover:text-gray-900'>
                    Terms &amp; Privacy
                  </a>
                </li>
                <li className='mt-3'>
                  <a className='text-gray-500 cursor-pointer hover:text-gray-900'>
                    FAQ
                  </a>
                </li>
              </nav>
            </div>
            <div className='w-full px-4 lg:w-1/4 md:w-1/2'>
              <h2 className='mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font'>
                Contact
              </h2>
              <nav className='mb-10 list-none'>
                <li className='mt-3'>
                  <Link
                    to='/contact#message'
                    className='text-gray-500 cursor-pointer hover:text-gray-900'
                  >
                    Send us a Message
                  </Link>
                </li>
                <li className='mt-3'>
                  <a className='text-gray-500 cursor-pointer hover:text-gray-900'>
                    +1 (857) 205-2672
                  </a>
                </li>
              </nav>
            </div>
          </div>
        </div>
        <div className='bg-zinc-800'>
          <div className='px-5 py-4 mx-auto'>
            <p className='text-sm text-gray-200 capitalize xl:text-center'>
              © Insite 2024 All rights reserved{" "}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
