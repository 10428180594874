import { DocumentTextIcon } from "@heroicons/react/24/outline";

const ResumeCell = ({ info }) => {
  return (
    <div className='w-full flex justify-center'>
      <a href={info} target='_blank' rel='noreferrer noopener'>
        <div className='transition-all focus:rounded-full focus:ring-zinc-900 hover:text-zinc-400 transition-all'>
          <DocumentTextIcon width={28} />
        </div>
      </a>
    </div>
  );
};

export default ResumeCell;
