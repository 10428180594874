import { DocumentArrowDownIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useOutletContext, useNavigate } from "react-router-dom";
import Stepper from "../components/stepper";

const CVUpload = () => {
  let [application, updateApplication] = useOutletContext();

  const navigate = useNavigate();
  console.log(application);

  let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  const clearCurrentCV = () => {
    acceptedFiles = [];
    updateApplication({ ...application, cv: "" });
  };

  const handleCVUpload = async () => {
    const formData = new FormData();
    formData.append("cv", acceptedFiles[0]);
    console.log(formData);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      await axios.post(
        "https://api-fly.flyawaystudents.com/api/applicants/${application.id}/sendCV",
        formData,
        config,
      );

      // Se o envio for bem-sucedido, você pode navegar para a próxima etapa
      navigate("/dashboard/application/edit/education");
    } catch (error) {
      console.error("Error uploading CV:", error.message);
    }
  };

  return (
    <motion.div
      className='flex flex-col h-full'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stepper currIndex={1} />
      <hr className='border-zinc-900 w-full' />
      <div className='flex flex-col h-full w-full items-center justify-between px-12'>
        <div className='w-full h-full mt-5'>
          {/* DropZone */}

          <div className='flex flex-col items-center'>
            <div className='flex w-full lg:w-3/4 justify-center shadow-lg items-center text-lg text-center p-10 mt-5 border-2 border-dashed border-zinc-900 rounded-lg'>
              <section className='container'>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} required type='file' />
                  <p>
                    Drag and drop your CV here, or click to select your file
                  </p>
                </div>
              </section>
            </div>
          </div>

          <div className='mt-5 p-2 flex flex-col items-center'>
            <label className='mt-4 px-3 font-semibold' htmlFor='uploads'>
              Uploaded Files
            </label>
            <div
              id='uploads'
              className='grid grid-cols-1 gap-6 rounded-lg p-6 mt-2 bg-zinc-200 shadow-lg w-1/2'
            >
              <div
                className={`${acceptedFiles.length > 0 ? "visible" : "hidden"} flex flex-col items-center rounded-lg shadow-md p-5 bg-white`}
              >
                <h4 className='font-bold text-lg'>Uploaded Resume:</h4>
                <ul>{files}</ul>
              </div>
              <div
                className={`${acceptedFiles.length < 1 ? "visible" : "hidden"} flex flex-col items-center rounded-lg shadow-md p-5 bg-white`}
              >
                <h4 className='font-bold text-lg'>Application Resume</h4>
                {application.cv == null || application.cv == "" ? (
                  <p className='text-center mt-4 font-light text-gray-400'>
                    No resume submitted yet.
                  </p>
                ) : (
                  <a
                    href={application.cv}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='text-black mt-2'
                  >
                    <DocumentArrowDownIcon width={30} />
                  </a>
                )}
              </div>
            </div>
            <div className='w-1/2 flex justify-center items-center py-10'>
              <button
                className='bg-red-600 text-white rounded-full p-3 font-semibold text-lg hover:brightness-75 hover:cursor-pointer'
                onClick={() => clearCurrentCV()}
              >
                Clear CV
              </button>
            </div>
          </div>
        </div>
        {/* Form Stepper Buttons */}
        <div className='flex flex-row justify-around w-full mt-8 px-10'>
          <button
            className='bg-zinc-700 shadow-md rounded-full p-3 px-4 text-gray-100 font-semibold hover:bg-zinc-600 transition-all'
            onClick={() => navigate("/dashboard/application/edit/personal")}
          >
            Previous
          </button>

          <button
            className='bg-red-600 shadow-md rounded-full p-3 px-4 text-white font-semibold hover:bg-red-500 transition-all'
            onClick={() => handleCVUpload}
          >
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default CVUpload;
