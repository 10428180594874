import {
  ChatBubbleLeftEllipsisIcon,
  CheckBadgeIcon,
  DocumentCheckIcon,
  GlobeAmericasIcon,
  PencilIcon,
  CheckCircleIcon,
  BriefcaseIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { DocumentArrowDownIcon } from "@heroicons/react/24/solid";
import { useDropzone } from "react-dropzone";
import { useOutletContext } from "react-router-dom";

const steps = [
  "Applied",
  "Prequalified",
  "CGFNS",
  "NCLEX",
  "Visa Cred",
  "Job Search",
  "Work Visa",
  "Completed",
];

const UserStatus = () => {
  let [application, updateApplication] = useOutletContext();

  console.log(application);

  let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  const clearCurrentDocument = () => {
    acceptedFiles = [];
    updateApplication({ ...application, cv: "" }); //CHANGE
  };

  const handleCVUpload = () => {
    // const formData = new FormData();
    // formData.append("file", acceptedFiles[0]);
  };

  return (
    <div className='flex h-full w-full bg-gray-100 p-10'>
      <div className=' h-full w-full bg-white rounded-xl shadow-lg p-10'>
        <h1 className='text-4xl font-semibold'>Application Status</h1>
        <hr className='border-zinc-900 my-5' />
        <div className='flex items-start max-w-screen-lg mx-auto mt-12'>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 1 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 1 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <DocumentCheckIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 1 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Pre-Qual</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 1 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 2 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 2 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <ChatBubbleLeftEllipsisIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 2 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Language</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 2 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 3 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 3 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <CheckBadgeIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 3 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>CGFNS</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 3 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 4 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 4 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <PencilIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 4 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>NCLEX</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 4 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 5 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 5 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <GlobeAmericasIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 5 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Visa Cred</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 5 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 6 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 6 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <DocumentMagnifyingGlassIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 6 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Job Search</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 6 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 7 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 7 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <BriefcaseIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 7 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Work Visa</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 7 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div>
            <div className='flex items-center'>
              <div
                className={`${application.status - 1 == 8 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 8 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <CheckCircleIcon width={22} />
                </span>
              </div>
            </div>
            <div className='mt-2'>
              <h6 className='text-base font-bold text-zinc-900'>Completion</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 == 8 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-full mt-12 shadow-lg items-center'>
          <h1 className='text-3xl'>
            Current Step: {steps[application.status - 2]}
          </h1>
          <div className='w-full h-full mt-5'>
            {/* DropZone */}
            <form onSubmit={handleCVUpload}>
              <div className='flex flex-col items-center'>
                <div className='flex w-full lg:w-3/4 justify-center shadow-lg items-center text-lg text-center p-10 mt-5 border-2 border-dashed border-zinc-900 rounded-lg'>
                  <section className='container'>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} required type='file' />
                      <p>
                        Drag and drop your CV here, or click to select your file
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </form>

            <div className='mt-5 p-2 flex flex-col items-center'>
              <label className='mt-4 px-3 font-semibold' htmlFor='uploads'>
                Uploaded Files
              </label>
              <div
                id='uploads'
                className='grid grid-cols-1 rounded-lg p-6 mt-2 bg-zinc-200 shadow-lg w-1/2'
              >
                <div
                  className={`${acceptedFiles.length > 0 ? "visible" : "hidden"} flex flex-col items-center rounded-lg shadow-md p-5 bg-white`}
                >
                  <h4 className='font-bold text-lg'>Uploaded Resume:</h4>
                  <ul>{files}</ul>
                </div>
                <div
                  className={`${application.cv ? "visible" : "hidden"} flex flex-col items-center rounded-lg shadow-md p-5 bg-white`}
                >
                  <h4 className='font-bold text-lg'>Current Resume</h4>
                  <a
                    href={application.cv}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='text-black mt-2'
                  >
                    <DocumentArrowDownIcon width={30} />
                  </a>
                </div>
              </div>
              <div className='w-1/2 flex justify-center items-center py-10'>
                <button
                  className='bg-red-600 text-white rounded-full p-3 font-semibold text-lg hover:brightness-75 hover:cursor-pointer'
                  onClick={() => clearCurrentDocument()}
                >
                  Clear Document
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserStatus;
