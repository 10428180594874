const VisaStatusCell = ({ info }) => {
  return (
    <div className='w-full flex justify-center'>
      <span
        className={`rounded-full whitespace-pre text-white text-center text-sm font-semibold px-4 py-1 ${info == "Citizen" ? "bg-green-500" : info == "Greencard Holder" ? "bg-blue-500" : "bg-red-500"}`}
      >
        {info}
      </span>
    </div>
  );
};

export default VisaStatusCell;
