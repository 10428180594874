import { countryToAlpha2 } from "country-to-iso";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

const CountryCell = ({ info }) => {
  if (info == null || info == "") {
    return (
      <div className='w-full flex justify-center'>
        <span>N/A</span>
      </div>
    );
  }
  const alpha2CountryCode = countryToAlpha2(info);
  return (
    <>
      {hasFlag(alpha2CountryCode) ? (
        <div className='flex justify-center m-2 min-w-[64px]'>
          <div className='flex justify-center items-center'>
            <span className='mr-3 text-3xl'>
              {getUnicodeFlagIcon(alpha2CountryCode)}
            </span>
            <span className='min-content whitespace-nowrap'>{info}</span>
          </div>
        </div>
      ) : (
        <div className='flex justify-center m-2 min-w-[64px]'>
          <span className='min-content whitespace-nowrap'>{info}</span>
        </div>
      )}
    </>
  );
};

export default CountryCell;
