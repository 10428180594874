import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo-no-bird.png";
import { login } from "../js/Api";

const LoginAdminForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate(); // Obtenha a função de navegação do hook useNavigate

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await login(formData);
      localStorage.setItem("token", response.token);
      localStorage.setItem("userId", response.user.id);
      localStorage.setItem("userName", response.user.name);
      localStorage.setItem("userEmail", response.user.email);
      navigate("/management"); // Use navigate para redirecionar para a rota /management
    } catch (error) {
      console.error("Erro ao fazer login:", error);
    }
  };

  return (
    <div className='flex h-full flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8 min-w-sm'>
      <div className='sm:flex sm:mx-auto sm:w-full sm:max-w-sm'>
        <img
          className='mx-auto h-14 w-auto'
          src={logo}
          alt='Fly Away Students Logo'
        />
      </div>
      <div className='p-10 mt-10 w-full min-w-sm max-w-lg rounded-2xl shadow-2xl border-2 border-zinc-900'>
        <h2 className='mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
          Sign in to your account
        </h2>
        <form className='space-y-6' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                value={formData.email}
                onChange={handleChange}
                className='block w-full rounded-md border-0 p-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
              />
            </div>
          </div>
          <div>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Password
              </label>
              <div className='text-sm'>
                <a
                  href='/management'
                  className='font-semibold text-red-600 hover:text-red-500'
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <div className='mt-2'>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                value={formData.password}
                onChange={handleChange}
                className='block w-full rounded-md border-0 p-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
              />
            </div>
          </div>
          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
            >
              Sign in
            </button>
          </div>
        </form>
        <p className='mt-10 text-center text-md text-gray-500'>
          Not a member?{" "}
          <a
            href='/register'
            className='font-semibold leading-6 text-red-600 hover:text-red-500 ml-2'
          >
            Register Now
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginAdminForm;
