import { useEffect, useState } from "react";
import NavBar from "../components/navigation/nav-bar";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { countryToAlpha2 } from "country-to-iso";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { Link } from "react-router-dom";

import fas_landing_page from "../videos/fas_landing_page.mp4";
import Footer from "../components/misc/footer";
import {
  BriefcaseIcon,
  CurrencyDollarIcon,
  PhoneIcon,
  PresentationChartLineIcon,
  MegaphoneIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/solid";

const Landing = () => {
  useEffect(() => {
    document.title = "Fly Away Students";
  }, []);

  const words = [" Nurses", " Doctors", " the World"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <motion.div
      className='flex flex-col h-full bg-zinc-800 overflow-auto'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='z-50'>
        <NavBar />
      </div>

      <div className='relative min-h-[1190px] object-cover w-full mt-10'>
        <div className='z-30 absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center text-center'>
          <div className='flex items-center justify-center w-full'>
            <div className='text-center flex justify-center'>
              <div className='grid grid-cols-1 text-center justify-center gap-4 align-middle text-6xl font-bold text-stone-100 mb-5 drop-shadow-lg'>
                <div>Connecting</div>
                <div>
                  <motion.ul
                    key={currentWordIndex}
                    initial={{ opacity: 0, y: -15 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.7 }}
                    className='text-6xl font-bold drop-shadow-lg'
                    style={{
                      transformStyle: "preserve-3d",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <motion.li
                      key={words[currentWordIndex]}
                      style={{ marginBottom: "0.5rem" }}
                      className={`${words[currentWordIndex] === " the World" ? "text-red-600" : "text-white"} break-keep`}
                    >
                      {words[currentWordIndex]}
                    </motion.li>
                  </motion.ul>
                </div>
              </div>
            </div>
          </div>
          <Link to='/register'>
            <div className='bg-red-700 text-white shadow-lg rounded-full cursor-pointer hover:bg-red-800 p-5 text-xl font-semibold inline-block'>
              Your Journey Starts Here
            </div>
          </Link>
        </div>
        <video
          className='w-full h-full object-cover'
          src={fas_landing_page}
          autoPlay
          loop
          muted
        >
          Video not available
        </video>
      </div>
      <div className='flex justify-center w-full'>
        <div className='flex flex-col h-full w-full lg:w-2/3 py-12 justify-center items-center'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6'>
            {/* <!-- Free Services --> */}
            <div className='p-6 bg-zinc-700 justify-around text-white shadow-md rounded-lg flex flex-col items-center text-center'>
              <CurrencyDollarIcon
                width={48}
                className='drop-shadow-lg text-red-600 mb-2'
              />
              <h3 className='text-xl font-bold mb-2'>Free Services</h3>
              <p className='text-center'>
                Our services are entirely free for nurses, doctors and students
                aiming to work in US hospitals.
              </p>
            </div>

            {/* <!-- Expertise and Experience --> */}
            <div className='p-6 bg-zinc-700 justify-around text-white shadow-md rounded-lg flex flex-col items-center text-center'>
              <PresentationChartLineIcon
                width={48}
                className='drop-shadow-lg text-red-600 mb-2'
              />
              <h3 className='text-xl font-bold mb-2'>
                Expertise and Experience
              </h3>
              <p className='text-center'>
                With over 5 years of experience, we have the expertise to guide
                you through the process.
              </p>
            </div>

            {/* <!-- Comprehensive Support --> */}
            <div className='p-6 bg-zinc-700 justify-around text-white shadow-md rounded-lg flex flex-col items-center text-center'>
              <PhoneIcon
                width={48}
                className='drop-shadow-lg text-red-600 mb-2'
              />
              <div className='flex flex-col justify-between'>
                <h3 className='text-xl font-bold mb-2'>
                  Comprehensive Support
                </h3>
                <p className='text-center'>
                  We provide support throughout the entire process, ensuring a
                  smooth transition.
                </p>
              </div>
            </div>

            {/* <!-- Job Opportunities in Massachusetts --> */}
            <div className='p-6 bg-zinc-700 justify-around text-white shadow-md rounded-lg flex flex-col items-center text-center'>
              <BriefcaseIcon
                width={48}
                className='drop-shadow-lg text-red-600 mb-2'
              />
              <h3 className='text-xl font-bold mb-2'>
                Job Opportunities in Massachusetts
              </h3>
              <p className='text-center'>
                Explore job openings in medical health in the state of
                Massachusetts.
              </p>
            </div>
          </div>

          <div className='w-full my-8'>
            <CountrySlider />
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
            {/* <!-- Partnerships with Hospitals --> */}
            <div className='p-6 bg-zinc-700 text-white shadow-md rounded-lg flex flex-col items-center'>
              <svg
                width='48px'
                height='48px'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='mb-2'
              >
                <path
                  d='M7 6H5.2C4.0799 6 3.51984 6 3.09202 6.21799C2.71569 6.40973 2.40973 6.71569 2.21799 7.09202C2 7.51984 2 8.0799 2 9.2V17.8C2 18.9201 2 19.4802 2.21799 19.908C2.40973 20.2843 2.71569 20.5903 3.09202 20.782C3.51984 21 4.0799 21 5.2 21H18.8C19.9201 21 20.4802 21 20.908 20.782C21.2843 20.5903 21.5903 20.2843 21.782 19.908C22 19.4802 22 18.9201 22 17.8V9.2C22 8.07989 22 7.51984 21.782 7.09202C21.5903 6.71569 21.2843 6.40973 20.908 6.21799C20.4802 6 19.9201 6 18.8 6H17M2 10H4M20 10H22M2 14H4M20 14H22M12 6V10M10 8H14M17 21V6.2C17 5.0799 17 4.51984 16.782 4.09202C16.5903 3.71569 16.2843 3.40973 15.908 3.21799C15.4802 3 14.9201 3 13.8 3H10.2C9.07989 3 8.51984 3 8.09202 3.21799C7.71569 3.40973 7.40973 3.71569 7.21799 4.09202C7 4.51984 7 5.0799 7 6.2V21H17ZM14 21V17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17V21H14Z'
                  stroke='rgb(220 38 38)'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <h3 className='text-xl font-bold mb-2'>
                Partnerships with Hospitals
              </h3>
              <p className='text-center'>
                Benefit from our partnerships with hospitals in the USA,
                increasing your chances of finding a suitable position.
              </p>
            </div>
            {/* <!-- Visa Sponsorship --> */}
            <div className='p-6 bg-zinc-700 text-white shadow-md rounded-lg flex flex-col items-center'>
              <svg
                fill='rgb(220 38 38)'
                height='48px'
                width='48px'
                className='mb-2'
                version='1.1'
                id='Capa_1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 612 612'
                xmlSpace='preserve'
              >
                <g>
                  <path
                    d='M202.772,404.687l-30.998,4.222c-8.503-11.019-14.959-23.733-18.73-37.611l39.719-5.41
		C195.223,379.208,198.589,392.343,202.772,404.687z M268.104,450.642c0.686-0.067,1.372-0.128,2.055-0.209
		c6.816-1.601,13.936-12.896,18.398-29.286l-45.408,6.186C251.946,442.194,261.166,450.416,268.104,450.642z M198.215,433.451
		c7.206,4.744,14.999,8.632,23.215,11.557c-2.697-4.264-5.257-8.838-7.669-13.677L198.215,433.451z M194.012,259.044
		c0.427-1.606,0.884-3.16,1.353-4.696c-7.795,5.475-14.893,12.001-21.109,19.494l16.995-2.315
		C192.041,267.215,192.95,263.035,194.012,259.044z M241.301,236.052c-0.12,0-0.237,0.02-0.354,0.025
		c-0.552,0.086-1.104,0.173-1.654,0.268c-6.827,1.841-13.93,13.331-18.326,29.863c-0.112,0.421-0.192,0.887-0.301,1.314
		l46.903-6.389C258.349,244.949,248.538,236.052,241.301,236.052z M289.297,324.593c-2.56-13.724-5.943-26.098-9.806-36.941
		l-63.26,8.618c-0.856,11.995-0.717,24.882,0.435,38.219L289.297,324.593z M188.069,300.104l-31.737,4.323
		c-4.775,12.472-7.17,25.736-7.059,39.239l39.705-5.408C187.791,325.132,187.498,312.3,188.069,300.104z M563.417,101.746
		l-52.283,366.432c-3.419,23.959-21.681,42.851-44.572,47.946c1.604,13.992-2.145,27.825-10.695,39.072
		c-8.79,11.563-21.558,19.012-35.954,20.972l-259.387,35.329c-2.449,0.335-4.934,0.502-7.388,0.502
		c-27.038,0-50.18-20.225-53.836-47.048L48.503,191.966c-1.961-14.393,1.802-28.689,10.592-40.254
		c8.791-11.563,21.558-19.012,35.954-20.972l91.709-12.491l9.82-68.84C200.603,21.24,225.064,0,253.479,0
		c2.722,0,5.472,0.195,8.18,0.583L514.59,36.671c15.213,2.17,28.669,10.135,37.892,22.425
		C561.705,71.387,565.584,86.536,563.417,101.746z M392.348,324.63c-9.142-67.134-67.056-117.757-134.713-117.757
		c-6.152,0-12.385,0.424-18.524,1.261c-0.51,0.07-1.015,0.17-1.523,0.245c-0.368,0.039-0.742,0.064-1.107,0.112
		c-0.828,0.112-1.64,0.293-2.451,0.46c-33.938,5.907-63.94,24.099-84.901,51.672c-22.007,28.943-31.422,64.724-26.514,100.748
		c3.648,26.781,15.074,50.919,31.768,70.282c0.714,1.04,1.531,1.991,2.488,2.797c25.08,27.534,61.11,44.68,100.455,44.68
		c0.003,0,0.006,0,0.006,0c4.359,0,8.754-0.223,13.133-0.644c0.923-0.053,1.843-0.131,2.755-0.257
		c0.22-0.031,0.435-0.089,0.655-0.123c0.658-0.081,1.319-0.145,1.977-0.234c36.027-4.906,67.984-23.549,89.988-52.492
		C387.844,396.437,397.257,360.656,392.348,324.63z M530.174,75.835c-4.752-6.334-11.682-10.436-19.522-11.554L257.721,28.193
		c-1.408-0.201-2.834-0.304-4.242-0.304c-14.625,0-27.219,10.946-29.289,25.46l-8.701,60.987l67.744-9.226l71.203-9.697
		c2.449-0.335,4.936-0.502,7.388-0.502c27.041,0,50.186,20.228,53.836,47.051l0.424,3.104c0,0.003,0,0.008,0.003,0.011
		l46.758,343.279c10.715-3.296,19.037-12.572,20.682-24.113L535.81,97.811C536.926,89.972,534.924,82.166,530.174,75.835z
		 M321.539,388.509l36.294-4.945c5.299-13.046,7.973-27.005,7.856-41.231l-44.968,6.124
		C321.974,362.268,322.236,375.752,321.539,388.509z M220.404,362.123c2.702,14.485,6.322,27.473,10.453,38.737l62.504-8.514
		c1.01-12.522,0.887-26.084-0.324-40.118L220.404,362.123z M315.691,427.675c-0.753,2.831-1.587,5.516-2.465,8.13
		c9.538-5.731,18.178-12.96,25.646-21.511l-20.771,2.828C317.392,420.751,316.592,424.279,315.691,427.675z M286.316,238.685
		c3.762,5.6,7.279,11.791,10.511,18.46l22.222-3.026C309.159,247.244,298.118,241.973,286.316,238.685z M361.919,314.701
		c-3.575-13.161-9.552-25.287-17.416-35.904l-36.972,5.037c3.904,11.816,7.067,24.322,9.41,36.994L361.919,314.701z'
                  />
                </g>
              </svg>
              <h3 className='text-xl font-bold mb-2'>Visa Sponsorship</h3>
              <p className='text-center'>
                Our clients sponsor visas, making your transition to the USA
                easier.
              </p>
            </div>
            {/* <!-- Testimonials and Success Stories --> */}
            <div className='p-6 bg-zinc-700 text-white shadow-md rounded-lg flex flex-col items-center'>
              <MegaphoneIcon width={48} className='text-red-600 mb-2' />
              <h3 className='text-xl font-bold mb-2'>
                Testimonials and Success Stories
              </h3>
              <p className='text-center'>
                Read testimonials from nurses who have successfully used our
                services.
              </p>
            </div>
            {/* <!-- Personalized Approach --> */}
            <div className='p-6 bg-zinc-700 text-white shadow-md rounded-lg flex flex-col items-center'>
              <SquaresPlusIcon width={48} className='text-red-600 mb-2' />
              <h3 className='text-xl font-bold mb-2'>Personalized Approach</h3>
              <p className='text-center'>
                We provide personalized assistance to ensure your needs are met.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default Landing;

const CountrySlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className='w-full text-black'>
      <Slider {...settings}>
        <div className='p-2'>
          <Link to='/contact#latinbranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/brazil.webp')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Brazil")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Brazil"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Brazil
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#usbranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/usa.jpeg')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("USA")) &&
                  getUnicodeFlagIcon(countryToAlpha2("USA"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                USA
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#latinbranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/peru.jpeg')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Peru")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Peru"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Peru
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#europebranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/albania.jpeg')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Albania")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Albania"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Albania
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#europebranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/croatia.webp')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Croatia")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Croatia"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Croatia
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#africabranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/ghana.jpeg')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Ghana")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Ghana"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Ghana
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#europebranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/serbia.jpeg')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Serbia")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Serbia"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Serbia
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#asiabranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/nepal.jpeg')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Nepal")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Nepal"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Nepal
              </div>
            </div>
          </Link>
        </div>
        <div className='p-2'>
          <Link to='/contact#africabranch'>
            <div
              className={`flex flex-col bg-white rounded-xl shadow-lg py-20 lg:py-32 text-center justify-center items-center bg-[url('./images/carousel/capeverde.jpeg')] bg-cover bg-center brightness-75 select-none hover:brightness-100 cursor-pointer hover:scale-105 transition-all`}
            >
              <div className='text-5xl'>
                {hasFlag(countryToAlpha2("Cape Verde")) &&
                  getUnicodeFlagIcon(countryToAlpha2("Cape Verde"))}
              </div>
              <div className=' text-white font-bold text-4xl drop-shadow-lg'>
                Cape Verde
              </div>
            </div>
          </Link>
        </div>
      </Slider>
    </div>
  );
};
