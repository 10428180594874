const VisaCell = ({ info }) => {
  return (
    <>
      {info ? (
        <div className={`flex justify-center w-full`}>
          <span
            className={`rounded-full text-white text-sm font-semibold px-4 py-1 ${info == 0 ? "bg-blue-500" : info == 1 ? "bg-indigo-500" : info == 2 ? "bg-green-500" : info == 3 ? "bg-gray-500" : "bg-red-500"}`}
          >
            {info == 0
              ? "J-1 Visa"
              : info == 1
                ? "H1-B Visa"
                : info == 2
                  ? "EB-3 Visa"
                  : info == 3
                    ? "Other Visa"
                    : "No Visa"}
          </span>
        </div>
      ) : (
        <div className='flex justify-center'>
          <span className='bg-gray-500 rounded-full text-white text-sm font-semibold px-4 py-1'>
            N/A
          </span>
        </div>
      )}
    </>
  );
};

export default VisaCell;
